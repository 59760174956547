import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import { DisplayTitle4 } from '@/styles/typography'
import { ButtonLink } from '@/elements/Link/Buttons'
import { Image } from '@/elements/Image/NextImage'
import { routes } from '@shared/constants/routes'
import { trackElementClicked } from '@/analytics/element'
import { objectStorageUrl } from '@/utils/objectStorageUrl'

export const WhatCanWeDeliverForYou = () => {
  const headline = 'What can we deliver for you?'
  const buttonText = 'Get started'
  const imageSrc = objectStorageUrl('grocery-bag.png')
  return (
    <StackWrapper>
      <ContentWrapper>
        <TextWrapper>
          <DisplayTitle4 shouldScaleUp color="plum">
            {headline}
          </DisplayTitle4>
          <SignupLink
            href={routes.SIGN_UP.url}
            onClick={() =>
              trackElementClicked({ type: 'button', content: 'get_started' })
            }
          >
            {buttonText}
          </SignupLink>
        </TextWrapper>
        <ImageContainer>
          <ImageWrapper>
            <Image src={imageSrc} alt="" fill sizes="320px" />
          </ImageWrapper>
        </ImageContainer>
      </ContentWrapper>
    </StackWrapper>
  )
}

const StackWrapper = styled.div`
  background: ${({ theme }) => theme.green};
  padding-top: 3.75rem;
  padding-left: 1.5rem;
  padding-bottom: 3.1875rem;

  @media ${screenSizes.tablet} {
    padding-top: 5rem;
    padding-left: 2.5rem;
  }

  @media ${screenSizes.smDesktop} {
    padding-top: 6.25rem;
    padding-left: 6.25rem;
  }

  @media ${screenSizes.lgDesktop} {
    padding-left: 0;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${screenSizes.tablet} {
    flex-direction: row;
  }

  @media ${screenSizes.lgDesktop} {
    max-width: 77.5rem;
    margin: auto;
  }
`
const TextWrapper = styled.div`
  padding-right: 6.875rem;

  @media ${screenSizes.tablet} {
    max-width: 24.6875rem;
    padding-right: 0;
  }

  @media ${screenSizes.smDesktop} {
    max-width: 38.0625rem;
  }
`
const SignupLink = styled(ButtonLink)`
  margin-top: 1.5rem;
`
const ImageContainer = styled.div`
  width: 100%;
`
const ImageWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 15rem;
  height: 16.25rem;
  margin-top: 2.875rem;
  margin-left: auto;
  margin-right: auto;

  @media ${screenSizes.tablet} {
    width: 17.1875rem;
    height: 18.75rem;
    margin-top: 0;
  }

  @media ${screenSizes.smDesktop} {
    width: 20.1875rem;
    height: 23.5rem;
  }
`
