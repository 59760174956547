import throttle from 'lodash/throttle'
import { type RefObject, useEffect } from 'react'

type Props = {
  frozen?: boolean
  root?: Element | null
  rootMargin?: string
  threshold: number
  callback: (observerEntries: IntersectionObserverEntry[]) => void
}

export const useIntersectionObserver = (
  elementRef: RefObject<HTMLDivElement>,
  { frozen, root = null, rootMargin, threshold, callback }: Props
) => {
  useEffect(() => {
    const node = elementRef?.current
    if (!node || frozen) return

    const observer = new window.IntersectionObserver(throttle(callback, 200), {
      threshold,
      root,
      rootMargin,
    })

    observer.observe(node)

    return () => {
      observer.disconnect()
    }
  }, [callback, elementRef, root, rootMargin, threshold, frozen])
}
