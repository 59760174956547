import styled from 'styled-components'
import { ArrowRightIcon } from '@shipt/design-system-icons'
import { TextButtonLink } from '@/elements/Link/Buttons'

interface Props {
  text: string
  href: string
}

export const FaqLink = ({ text, href }: Props) => {
  return (
    <PrimaryLink href={href}>
      {text}
      <ArrowRightIcon />
    </PrimaryLink>
  )
}

const PrimaryLink = styled(TextButtonLink)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
`
