import { type ReactNode, useState } from 'react'
import styled from 'styled-components'
import { ScrollIntoView } from '@/components/ScrollIntoView'
import { fadeInFromBottom } from '@/styles/effects'

type Props = {
  children: ReactNode
  minHeight?: number
}

export const FadeInFromBottom = ({ minHeight = 0, children }: Props) => {
  const [isInView, setIsInView] = useState(false)

  return (
    <Container minHeight={minHeight}>
      <ScrollIntoView onInView={() => setIsInView(true)} threshold={0.15}>
        {isInView && <Wrapper>{children}</Wrapper>}
      </ScrollIntoView>
    </Container>
  )
}

const Wrapper = styled.div`
  animation: ${fadeInFromBottom} 1s;
`
const Container = styled.div<{ minHeight: number }>`
  min-height: ${(props) => props.minHeight}px;
`
