import { RouteName, routes } from '@shared/constants/routes'
import { UngatedHomePage } from '@/components/UngatedHomePage'
import { createServerSidePropsHandler } from '@/serverUtils/ssrHelpers/createServerSidePropsHandler'
import { handleAddressRemovalExperimentSSR } from '@/components/AddressRemoval/useAddressRemovalExperiment'
import { fetchExperimentsSSR } from '@/services/Experiments/queries'
import { shouldUseNewAuth } from '@/utils/authentication/utils'
import { fetchCMSDataForSSR } from '@/services/CMS/utils'
import { getPageRedirect } from '@/serverUtils/ssrHelpers/utils'
import { withoutHeaderLayout } from '@/components/Headers/Layouts/withoutHeaderLayout'

export const getServerSideProps = createServerSidePropsHandler(
  RouteName.UNGATED_HOMEPAGE,
  async ({ queryClient, ssrContext, authContext }) => {
    const { isAuthenticated, isOnboarded } = authContext
    if (isAuthenticated) {
      if (shouldUseNewAuth(ssrContext) && !isOnboarded) {
        return getPageRedirect(routes.WELCOME.url)
      }
      return getPageRedirect(routes.GLOBAL_HOMEPAGE.url)
    }

    const [removalExperimentResult, cmsData] = await Promise.all([
      handleAddressRemovalExperimentSSR({ queryClient, ssrContext }),
      fetchCMSDataForSSR({ queryClient, ssrContext, authContext }),
      fetchExperimentsSSR({ queryClient, ssrContext }),
    ])
    return { ...removalExperimentResult, ...cmsData }
  }
)

// header is controlled via CMS
export default withoutHeaderLayout(UngatedHomePage)
