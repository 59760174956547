import styled from 'styled-components'
import { Image } from '@/elements/Image/NextImage'
import { screenSizes } from '@shipt/design-system-themes'
import { FadeInFromBottom } from '@/components/FadeInFromBottom'
import { objectStorageUrl } from '@/utils/objectStorageUrl'

export const MemberShopperPhotos = () => (
  <PhotosContainer>
    <BigImgContainer>
      <FadeInFromBottom minHeight={325}>
        <Img
          src={objectStorageUrl('shopper-shopping.png')}
          alt="Shopper Shopping"
          width={612}
          height={613}
          style={{ objectFit: 'contain' }}
        />
      </FadeInFromBottom>
    </BigImgContainer>
    <SmallImgContainer>
      <FadeInFromBottom minHeight={100}>
        <Img
          src={objectStorageUrl('customers.png')}
          alt="Customer on laptop"
          width={612}
          height={404}
          style={{ objectFit: 'contain' }}
        />
      </FadeInFromBottom>
    </SmallImgContainer>
  </PhotosContainer>
)

const PhotosContainer = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 1.5625rem 1.5625rem 3.125rem;
  gap: 0.9375rem;

  @media ${screenSizes.tablet} {
    margin: 1.875rem 2.5rem 3.75rem;
    flex-direction: row;
  }

  @media ${screenSizes.smDesktop} {
    margin: 1.875rem 6.25rem 3.75rem;
    min-height: 37.5rem;
  }

  @media ${screenSizes.lgDesktop} {
    max-width: 77.5rem;
    margin: 1.875rem auto 3.75rem;
  }
`

const Img = styled(Image)`
  border-radius: 8px;
`

const SmallImgContainer = styled.div`
  display: flex;
  padding-right: 20px;

  @media ${screenSizes.tablet} {
    justify-content: flex-end;
    padding-right: 0;
    width: 50%;
  }
`

const BigImgContainer = styled.div`
  @media ${screenSizes.tablet} {
    width: 50%;
  }
`
