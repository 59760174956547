import styled from 'styled-components'
import { TagsTitle1, Title0 } from '@/styles/typography'
import { screenSizes } from '@shipt/design-system-themes'
import { FaqLink } from '@/components/UngatedHomePage/faq/FaqLink'
import { ButtonLink } from '@/elements/Link/Buttons'
import { cmsRoutes } from '@shared/constants/routes'

export const FAQ = () => {
  return (
    <FAQContainer>
      <TitleContainer>
        <TinyFAQ>FAQ</TinyFAQ>
        <Title0>How can we help?</Title0>
      </TitleContainer>
      <LinksContainer>
        <LinksWrapper>
          <LinksColumn>
            <FaqLink
              text="How Shipt works"
              href="https://help.shipt.com/how-shipt-works"
            />
            <FaqLink
              text="Become a shopper with Shipt"
              href={cmsRoutes.SHOPPER.BE_A_SHOPPER}
            />
          </LinksColumn>
          <LinksColumnRight>
            <FaqLink text="Account help" href="https://help.shipt.com/" />
            <FaqLink
              text="About deliveries"
              href="https://www.shipt.com/delivery/grocery-delivery/"
            />
          </LinksColumnRight>
        </LinksWrapper>
        <ExploreButton variant="tertiary" href="https://help.shipt.com/">
          Explore FAQs
        </ExploreButton>
      </LinksContainer>
    </FAQContainer>
  )
}

const FAQContainer = styled.div`
  margin: 3.125rem 1.5rem;

  @media ${screenSizes.tablet} {
    display: flex;
    margin: 3.75rem 2.5rem;
  }

  @media ${screenSizes.smDesktop} {
    display: flex;
    justify-content: space-between;
    margin: 3.75rem 6.25rem;
  }

  @media ${screenSizes.lgDesktop} {
    max-width: 77.5rem;
    margin-left: auto;
    margin-right: auto;
  }
`

const TinyFAQ = styled(TagsTitle1)`
  color: ${({ theme }) => theme.gray600};
  margin-bottom: 1rem;

  @media ${screenSizes.tablet} {
    font-size: 1rem;
  }

  @media ${screenSizes.smDesktop} {
    font-size: 1rem;
  }
`

const TitleContainer = styled.div`
  margin-top: 3.125rem;

  @media ${screenSizes.tablet} {
    flex-direction: column;
  }
`
const LinksContainer = styled.div`
  margin-top: 3.125rem;

  @media ${screenSizes.tablet} {
    margin-top: 4.375rem;
    margin-left: 9.375rem;
  }

  @media ${screenSizes.smDesktop} {
    justify-content: space-between;
    margin-top: 4.375rem;
    margin-left: 9.375rem;
  }
`

const LinksColumn = styled.div`
  @media ${screenSizes.smDesktop} {
    margin-left: 12.5rem;
  }
`

const LinksColumnRight = styled.div`
  @media ${screenSizes.smDesktop} {
    margin-left: 12.5rem;
  }
`

const LinksWrapper = styled.div`
  @media ${screenSizes.smDesktop} {
    display: flex;
    justify-content: center;
  }
`
const ExploreButton = styled(ButtonLink)`
  margin-top: 3.75rem;

  @media ${screenSizes.smDesktop} {
    margin-left: 12.5rem;
  }
`
