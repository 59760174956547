import styled from 'styled-components'
import { spacing as getSpacing } from '@/theme/tokens'
import { type ShiptTheme } from '@/constants/shiptStaticData'

type ContentPosition =
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'start'
  | 'baseline'
type ContentDistribution =
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'stretch'

interface StackProps {
  spacing?: keyof ShiptTheme['spacing'] | number
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  align?: ContentPosition
  justify?: ContentPosition | ContentDistribution
}

/**
 * @deprecated Use { Stack } from '@shipt/design-system-layouts' instead
 */
export const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};

  /**
  * Flexbox support for the 'gap' property is supported in all latest
  * versions of the browsers we support. For older versions we default to
  * the lobotomized owl syntax
  */
  @supports (gap: 50px) {
    gap: ${({ theme, spacing }) =>
      spacing ? getSpacing(spacing)({ theme }) : null};
  }

  @supports not (gap: 50px) {
    & > * + * {
      margin-left: ${({ theme, spacing, direction = 'row' }) =>
        direction === 'row' && spacing ? getSpacing(spacing)({ theme }) : null};
      margin-top: ${({ theme, spacing, direction = 'row' }) =>
        direction === 'column' && spacing
          ? getSpacing(spacing)({ theme })
          : null};
    }
  }
`
/**
 * @deprecated Use { Column } from '@shipt/design-system-layouts' instead
 */
export const VStack = styled(Stack).attrs({ direction: 'column' })``
/**
 * @deprecated Use { Row } from '@shipt/design-system-layouts' instead
 */
export const HStack = styled(Stack).attrs({ direction: 'row' })``
